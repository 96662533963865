<template>
  <div>
    <BaseButton
      class="justify-center w-full"
      @click="showUpgradeModal = true"
    >
      Upgrade to {{ plan.name }}
    </BaseButton>
    <Modal
      :open="showUpgradeModal"
      :can-close="!upgradeInProgress"
      @close="showUpgradeModal = false"
    >
      <div class="bg-white border-b-2">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="text-lg font-medium">
            Upgrade subscription
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="showUpgradeModal = false"
          >
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5 transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <div
        v-if="!upgradeInProgress"
        class="p-6"
      >
        <p class="mb-3">
          When you upgrade, we’ll end your current subscription, and start a new one.
        </p>
        <p
          v-if="billed"
          class="my-3"
        >
          You’ll be billed {{ billed }} today to start your new subscription, and today will become your renewal date.
        </p>
        <p
          v-else
          class="my-3"
        >
          Payment is taken now for your new subscription, and today will become your renewal date.
        </p>
        <p class="my-3">
          As well as any unused credits, you’ll be given:
        </p>
        <ul class="my-3 list-disc list-inside">
          <li>{{ numberFormat(plan.assessment_credits) }} extra assessment credits</li>
          <li>{{ numberFormat(plan.candidate_credits) }} extra candidate credits</li>
        </ul>
        <p class="my-3">
          You can use this straight after upgrading.
        </p>
        <p class="my-3">
          You you like to upgrade now?
        </p>
        <p class="flex justify-between mt-6">
          <BaseButton
            variant="salmon"
            @click="showUpgradeModal = false"
          >
            No, Keep Existing Plan
          </BaseButton>
          <BaseButton @click="upgradeSubscription()">
            Upgrade Now
          </BaseButton>
        </p>
        <p
          v-if="upgradeErrorMessage"
          class="my-3"
        >
          {{ upgradeErrorMessage }}
        </p>
      </div>
      <div
        v-else-if="upgradeInProgress"
        class="p-6 text-center"
      >
        <Loader />
        <p class="my-3 text-sm">
          Updating your subscription
        </p>
      </div>
    </Modal>
  </div>
</template>

<script>
import Plus from '@components/Icons/Plus'
import Icon from '@components/Icons/Icon'
import Loader from '@components/Loader'
import Modal from '@components/Modal'

import { numberFormat } from '@utils/numberFormat'

export default {
  components: {
    Loader,
    Modal,
    Icon,
    Plus
  },

  props: {
    plan: {
      type: Object,
      required: true
    },
    billed: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      numberFormat,

      showUpgradeModal: false,
      upgradeInProgress: false,
      upgradeErrorMessage: null
    }
  },

  methods: {
    /**
     * Start process for upgrading a subscription
     */
    upgradeSubscription() {
      if (this.upgradeInProgress) {
        return
      }

      this.upgradeInProgress = true
      this.upgradeErrorMessage = null

      this.$store.dispatch('subscriptions/upgradePaygSubscription', this.plan.slug)
        .then(() => {
          this.$store.dispatch('subscriptions/getSubscriptions')
            .then(() => {
              this.upgradeInProgress = false
              this.$router.push({
                name: 'settings-upgrade-complete'
              })
            })
        })
        .catch(error => {
          this.upgradeInProgress = false
          this.upgradeErrorMessage = 'Sorry, we couldn’t process upgrading your subscription right now.'
          throw error
        })
    }
  }
}
</script>
